// /** @jsx jsx */
// import { jsx, Spinner } from "theme-ui";
// import RenderAbhyasiPrint from "gatsby-plugin-hfn-profile/components/SrcmEntities/Render/RenderAbhyasiPrint";

// import { useQueryParam, StringParam } from "use-query-params";
// import { validateAbhyasiId } from "sites-common/utils/validations";

// import { useLoginWithSbt } from "gatsby-plugin-hfn-profile/components/Login";

// const Idcard = () => {
//   const [ref] = useQueryParam("id", StringParam);
//   const [sbt] = useQueryParam("token", StringParam);
//   const [requestor] = useQueryParam("requestor", StringParam);

//   const { loading, error } = useLoginWithSbt(sbt);

//   if (!sbt) return <div sx={{ bg: "red", color: "white" }}>Invalid Token</div>;
//   if (requestor !== "uep")
//     return <div sx={{ bg: "red", color: "white" }}>Invalid Requestor</div>;

//   if (!validateAbhyasiId(ref))
//     return <div sx={{ bg: "red", color: "white" }}>Invalid ID</div>;

//   if (error) return <div sx={{ bg: "red", color: "white" }}>Invalid Token</div>;

//   if (loading) {
//     return (
//       <div sx={{ height: "100%" }}>
//         <Spinner />
//         <div>Verifying the token</div>
//       </div>
//     );
//   }

//   return <RenderAbhyasiPrint abhyasiId={ref} />;
// };

const Idcard = () => null;
export default Idcard;
